import type { RouterConfig } from '@nuxt/schema';

const pages = [
    {
        path: '/3dsecure',
        name: '3dsecure',
        component: () => import('@/pages/3dsecure.vue')
    },
    {
        path: '/akcii/:slug',
        name: 'akcii-slug',
        component: () => import('@/pages/akcii/[slug].vue')
    },
    {
        path: '/akcii',
        name: 'akcii',
        component: () => import('@/pages/akcii/index.vue')
    },
    {
        path: '/app',
        name: 'app',
        component: () => import('@/pages/app.vue')
    },
    {
        path: '/appredirect',
        name: 'appredirect',
        component: () => import('@/pages/appredirect.vue')
    },
    {
        path: '/checkout',
        name: 'checkout',
        component: () => import('@/pages/checkout.vue'),
        meta: {
            middleware: ["auth", "address"],
        }
    },
    {
        path: '/contacts',
        name: 'contacts',
        component: () => import('@/pages/contacts.vue')
    },
    {
        path: '/getapp',
        name: 'getapp',
        component: () => import('@/pages/getapp.vue'),
        meta: {
            middleware: ['getapp']
        }
    },
    {
        path: '/',
        name: 'index',
        component: () => import('@/pages/index.vue'),
        meta: {
            scrollToTop: false
        },
        children: [
            {
                path: 'address',
                name: 'index-address',
                component: () => import('@/pages/index/address/index.vue')
            },
            {
                path: 'auth/login',
                name: 'index-auth-login',
                component: () => import('@/pages/index/auth/login.vue'),
                meta: {
                    middleware: ["guest"]
                }
            },
            {
                path: 'auth/profile',
                name: 'index-auth-profile',
                component: () => import('@/pages/index/auth/profile.vue'),
                meta: {
                    middleware: ["auth"],
                }
            },
            {
                path: 'auth/register',
                name: 'index-auth-register',
                component: () => import('@/pages/index/auth/register.vue')
            },
            {
                path: 'cart',
                name: 'index-cart',
                component: () => import('@/pages/index/cart/index.vue')
            },
            {
                path: 'groups/:id',
                name: 'index-groups-id',
                component: () => import('@/pages/index/groups/[id].vue')
            },
            {
                path: 'halves/:id',
                name: 'index-halves-id',
                component: () => import('@/pages/index/halves/[id].vue')
            },
            {
                path: 'products/:id',
                name: 'index-products-id',
                component: () => import('@/pages/index/products/[id].vue')
            }
        ]
    },
    {
        path: '/oferta',
        name: 'oferta',
        component: () => import('@/pages/oferta.vue')
    },
    {
        path: '/orders/:id',
        name: 'orders-id',
        component: () => import('@/pages/orders/[id].vue'),
        meta: {
            middleware: ["auth"],
        }
    },
    {
        path: '/orders',
        name: 'orders',
        component: () => import('@/pages/orders/index.vue'),
        meta: {
            middleware: ["auth"],
        }
    },
    {
        path: '/policy',
        name: 'policy',
        component: () => import('@/pages/policy.vue')
    },
    {
        path: '/profile',
        name: 'profile',
        component: () => import('@/pages/profile.vue'),
        meta: {
            middleware: ["auth"],
        }
    },
    {
        path: '/rules',
        name: 'rules',
        component: () => import('@/pages/rules.vue')
    },
    {
        path: '/success-order',
        name: 'success-order',
        component: () => import('@/pages/success-order.vue')
    },
];

const addMiddleware = (page: any) => {
    let newPage = {
        ...page,
        name: `${page.name}-with-city`
    };
    newPage.meta ||= {};
    newPage.meta.middleware ||= [];
    newPage.meta.middleware.push('check-city');


    if (newPage.children && newPage.children.length > 0) {
        newPage.children = newPage.children.map((child: any) => addMiddleware(child));
    }
    return newPage;
};

const getPages = () => {
    let originalPages = [...pages];
    let newPages = [] as any[];

    originalPages.forEach((page) => {
        const newPage = addMiddleware(page);
        newPages.push({
            ...newPage,
            path: `/:city${newPage.path === '/' ? '' : newPage.path}`,
        });
    });

    originalPages.push(...newPages);

    return originalPages;
};

export default {
    routes: (_routes) => getPages(),
} satisfies RouterConfig;