import plugin from 'vue-yandex-maps';

const settings = {
    apiKey: '070e27ee-87c4-459e-8a21-7c21bcfb58c6',
    lang: 'ru_RU',
    coordorder: 'longlat',
    debug: false,
    version: '2.1'
};

export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.vueApp.use(plugin, settings);
});