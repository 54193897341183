import revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y from "/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8 from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3__Mow596nDQwJvvpPi_lHWeKcoEwbUskdc2VRygziuCY from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import floating_vue_0VJpPLm37OmsRfOoVrjUztB_kqo8e0M_PqepnJl50BQ from "/app/.nuxt/floating-vue.mjs";
import plugin_6nXalwyPO_Ex4TtjwXHf2aYF5C_cVyJe2INVAxrt_CU from "/app/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import plugin_7DB4Q_rSg7Y6_r2xTJAR9sj0Plzl7GeeI8C3uqDzeqY from "/app/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import plugin_pctIVG_FA3ygvTkx4z_pYtRdHdDEZ_qPbl4DqvN9CMQ from "/app/node_modules/dayjs-nuxt/dist/runtime/plugin.js";
import _01_loadToken_dpoEZkGK593xq1jq8psK1zOnnXuCaM4T9yFCGFeeMzY from "/app/plugins/01.loadToken.ts";
import _10_tags_hn1aDte1OC1VYMzhrfw7rIJlypkGvW_FP_Ozr9Cp2II from "/app/plugins/10.tags.ts";
import _20_loadData_ghS37OKv5aMd74_FTIrmHQS6qAMyxLx2zKmdgIH0kJ0 from "/app/plugins/20.loadData.ts";
import _30_insider_client_O1fw_fIzEiQJwDMcNB1DtTxOCqBZMFPvI11HaFMiIbw from "/app/plugins/30.insider.client.ts";
import customScroll_client_6_Yn1wNgjR3kUIloP5O1_2lZVYXMlCOGZzqkv50x8k0 from "/app/plugins/customScroll.client.ts";
import flip_client_hWI5BUFqEkbUFDiZs1TnKFmKQjIWJArbYvvMQ5EpSSg from "/app/plugins/flip.client.ts";
import maska_3Pdj_1ePE2_bvNiJO2MTIpupg59ALfbg8NtShv_Xmbo from "/app/plugins/maska.ts";
import masonry_JIMXhbXhCyo47Oze7KI1eszXmS_7GdLxu_shW22IxmU from "/app/plugins/masonry.ts";
import mitt_gX99hIqc_4Ds_oxpW9ZggFIQt_TwlHPZ_D1tG4_2Jpg from "/app/plugins/mitt.ts";
import sentry_client_lpcrpmTLjShWJPDmp0fi6OOuaOcqqIWIe5I186xHGwE from "/app/plugins/sentry.client.ts";
import useBootstrap_client_bMeZY0nrQr9XabN5OUDuL9psNe3VgPRvmdhA6BDCcGo from "/app/plugins/useBootstrap.client.ts";
import vCalendar_client_dd9KjDGh4CiLYBKt_MMeQPn7VUQu2W7DeEHxtUA07N8 from "/app/plugins/vCalendar.client.ts";
import yandexMap_client_1Zx_tDrXKH0zZHkj7p9_7EW_uYOWDQoyg4vqiHgOo1Q from "/app/plugins/yandexMap.client.ts";
export default [
  revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY,
  unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU,
  router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw,
  payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM,
  navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y,
  check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8,
  chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U,
  plugin_vue3__Mow596nDQwJvvpPi_lHWeKcoEwbUskdc2VRygziuCY,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E,
  floating_vue_0VJpPLm37OmsRfOoVrjUztB_kqo8e0M_PqepnJl50BQ,
  plugin_6nXalwyPO_Ex4TtjwXHf2aYF5C_cVyJe2INVAxrt_CU,
  plugin_7DB4Q_rSg7Y6_r2xTJAR9sj0Plzl7GeeI8C3uqDzeqY,
  plugin_pctIVG_FA3ygvTkx4z_pYtRdHdDEZ_qPbl4DqvN9CMQ,
  _01_loadToken_dpoEZkGK593xq1jq8psK1zOnnXuCaM4T9yFCGFeeMzY,
  _10_tags_hn1aDte1OC1VYMzhrfw7rIJlypkGvW_FP_Ozr9Cp2II,
  _20_loadData_ghS37OKv5aMd74_FTIrmHQS6qAMyxLx2zKmdgIH0kJ0,
  _30_insider_client_O1fw_fIzEiQJwDMcNB1DtTxOCqBZMFPvI11HaFMiIbw,
  customScroll_client_6_Yn1wNgjR3kUIloP5O1_2lZVYXMlCOGZzqkv50x8k0,
  flip_client_hWI5BUFqEkbUFDiZs1TnKFmKQjIWJArbYvvMQ5EpSSg,
  maska_3Pdj_1ePE2_bvNiJO2MTIpupg59ALfbg8NtShv_Xmbo,
  masonry_JIMXhbXhCyo47Oze7KI1eszXmS_7GdLxu_shW22IxmU,
  mitt_gX99hIqc_4Ds_oxpW9ZggFIQt_TwlHPZ_D1tG4_2Jpg,
  sentry_client_lpcrpmTLjShWJPDmp0fi6OOuaOcqqIWIe5I186xHGwE,
  useBootstrap_client_bMeZY0nrQr9XabN5OUDuL9psNe3VgPRvmdhA6BDCcGo,
  vCalendar_client_dd9KjDGh4CiLYBKt_MMeQPn7VUQu2W7DeEHxtUA07N8,
  yandexMap_client_1Zx_tDrXKH0zZHkj7p9_7EW_uYOWDQoyg4vqiHgOo1Q
]