
export default defineNuxtPlugin(async () => {
    if (!import.meta.client) {
        return;
    }

    const route = useRoute();
    const { $dayjs } = useNuxtApp();

    if (route.query.utm_source) {
        const sourceCookie = useCookie('utmSource', {
            expires: $dayjs().add(7, 'day').toDate(),
            maxAge: 60 * 60 * 24 * 7,
            sameSite: true
        });
        sourceCookie.value = route.query.utm_source;
    }

    if (route.query.admitad_uid) {
        const sourceUidCookie = useCookie('uidSource', {
            expires: $dayjs().add(7, 'day').toDate(),
            maxAge: 60 * 60 * 24 * 7,
            sameSite: true
        });
        sourceUidCookie.value = route.query.admitad_uid;
    }
});