
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _3dsecureblKHQuJISHsnnMNjAdfPDoCqSlzo2z62RlprXARBym8Meta } from "/app/pages/3dsecure.vue?macro=true";
import { default as _91slug_93w12xy24KXtjTNpm9CPFo0y_7G8iOFAYuzWs9b479GjYMeta } from "/app/pages/akcii/[slug].vue?macro=true";
import { default as indexkxO_NVy33dNIHQ1JOTvqD_EEW2Xh5Vrp2fWeSBFClIUMeta } from "/app/pages/akcii/index.vue?macro=true";
import { default as appoARvJ9pTIAlSwGNu9CDvfiuUETdLPDqEIX3DFwG66D0Meta } from "/app/pages/app.vue?macro=true";
import { default as appredirectjOjx_WwrW9S_45FZOxRtmwZok6htBUrJVIemEdYHkbb5AMeta } from "/app/pages/appredirect.vue?macro=true";
import { default as checkoutrJOJ_qEKslhH7u_45okx_45tCaYkZe6jupNB2VSAVuoHgEMMeta } from "/app/pages/checkout.vue?macro=true";
import { default as contactskxHN03Gsdgdk5hItUiIn_45VHejdeYe8Yz7_45onvuu94gkMeta } from "/app/pages/contacts.vue?macro=true";
import { default as getappUmjbYg_45ZErJSDaWxOxtcdD6zfbuNig5Fy7xJPg4hlE4Meta } from "/app/pages/getapp.vue?macro=true";
import { default as indexcyy2dq8874Efbf3Ep3QUfOSaXJoCaX9yAggVzSMtXSEMeta } from "/app/pages/index/address/index.vue?macro=true";
import { default as loginsr3rRlGqIvaU7ecoHIGP0boROlQRb9bxoNa4VSwnIl4Meta } from "/app/pages/index/auth/login.vue?macro=true";
import { default as profilewoFEEhDpAY90RlYBjtNppm1cxhV8zMuIbRKJYuc7k5gMeta } from "/app/pages/index/auth/profile.vue?macro=true";
import { default as register1fDsH0XpPNJBHYqbay_OqlgCPMLF95rcPzxjjGe2axAMeta } from "/app/pages/index/auth/register.vue?macro=true";
import { default as indexoGtdPI49B5qq7tX9ERmwXZnUUM69nWmW5zPIgtpINLMMeta } from "/app/pages/index/cart/index.vue?macro=true";
import { default as _91id_93UlLsYstuJDHJSO8k6GMkjjTO_QbwWNly34Ah9NuvXJgMeta } from "/app/pages/index/groups/[id].vue?macro=true";
import { default as _91id_93A_W1BgInwUgM1l9rSd2crPvjCo4By7CZhu5KDWN0HBgMeta } from "/app/pages/index/halves/[id].vue?macro=true";
import { default as _91id_93j_gN1Fcj2fw14j8UDGzPMwx_ob0z4jBzcBKv8ukOgK8Meta } from "/app/pages/index/products/[id].vue?macro=true";
import { default as indexndqPXFtP262szLmLJV4PriPTgAg5k_457f05QyTfosBXQMeta } from "/app/pages/index.vue?macro=true";
import { default as ofertaqAK4TFIbMiZSxgwa9lSDULqSzq3LwOcUUprKKiZKM4IMeta } from "/app/pages/oferta.vue?macro=true";
import { default as _91id_93z_45mKuW1rMZYIKaD_45jb7RkOPiwtU5vi28dxEw6FTfUakMeta } from "/app/pages/orders/[id].vue?macro=true";
import { default as indexDCqhPzutOZTeEBErhbpXyxz9LSZCrOqFoLSpaIOOmIIMeta } from "/app/pages/orders/index.vue?macro=true";
import { default as policyHyFEmCLoA_gbds_45pFYhSvKcu4HdwgUBD6ogvbj_45r9y0Meta } from "/app/pages/policy.vue?macro=true";
import { default as profilepJO2Lu3IltPzloUxFV8Oe0uuAaiSUhc1LFMplh8Hci0Meta } from "/app/pages/profile.vue?macro=true";
import { default as rulesoW3OsXfUxMGzTjcJPfE4v0oEYZyNpAmFSWz4Vk42uNYMeta } from "/app/pages/rules.vue?macro=true";
import { default as success_45order_1bVsa_45QoY0eqwzJqrCwZmIKRitYbdgdyD7_45LQdjot0Meta } from "/app/pages/success-order.vue?macro=true";
import { default as component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta } from "/app/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIA } from "/app/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "3dsecure",
    path: "/3dsecure",
    component: () => import("/app/pages/3dsecure.vue")
  },
  {
    name: "akcii-slug",
    path: "/akcii/:slug()",
    component: () => import("/app/pages/akcii/[slug].vue")
  },
  {
    name: "akcii",
    path: "/akcii",
    component: () => import("/app/pages/akcii/index.vue")
  },
  {
    name: "app",
    path: "/app",
    component: () => import("/app/pages/app.vue")
  },
  {
    name: "appredirect",
    path: "/appredirect",
    meta: appredirectjOjx_WwrW9S_45FZOxRtmwZok6htBUrJVIemEdYHkbb5AMeta || {},
    component: () => import("/app/pages/appredirect.vue")
  },
  {
    name: "checkout",
    path: "/checkout",
    meta: checkoutrJOJ_qEKslhH7u_45okx_45tCaYkZe6jupNB2VSAVuoHgEMMeta || {},
    component: () => import("/app/pages/checkout.vue")
  },
  {
    name: "contacts",
    path: "/contacts",
    component: () => import("/app/pages/contacts.vue")
  },
  {
    name: "getapp",
    path: "/getapp",
    meta: getappUmjbYg_45ZErJSDaWxOxtcdD6zfbuNig5Fy7xJPg4hlE4Meta || {},
    component: () => import("/app/pages/getapp.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexndqPXFtP262szLmLJV4PriPTgAg5k_457f05QyTfosBXQMeta || {},
    component: () => import("/app/pages/index.vue"),
    children: [
  {
    name: "index-address",
    path: "address",
    component: () => import("/app/pages/index/address/index.vue")
  },
  {
    name: "index-auth-login",
    path: "auth/login",
    meta: loginsr3rRlGqIvaU7ecoHIGP0boROlQRb9bxoNa4VSwnIl4Meta || {},
    component: () => import("/app/pages/index/auth/login.vue")
  },
  {
    name: "index-auth-profile",
    path: "auth/profile",
    meta: profilewoFEEhDpAY90RlYBjtNppm1cxhV8zMuIbRKJYuc7k5gMeta || {},
    component: () => import("/app/pages/index/auth/profile.vue")
  },
  {
    name: "index-auth-register",
    path: "auth/register",
    component: () => import("/app/pages/index/auth/register.vue")
  },
  {
    name: "index-cart",
    path: "cart",
    component: () => import("/app/pages/index/cart/index.vue")
  },
  {
    name: "index-groups-id",
    path: "groups/:id()",
    component: () => import("/app/pages/index/groups/[id].vue")
  },
  {
    name: "index-halves-id",
    path: "halves/:id()",
    component: () => import("/app/pages/index/halves/[id].vue")
  },
  {
    name: "index-products-id",
    path: "products/:id()",
    component: () => import("/app/pages/index/products/[id].vue")
  }
]
  },
  {
    name: "oferta",
    path: "/oferta",
    meta: ofertaqAK4TFIbMiZSxgwa9lSDULqSzq3LwOcUUprKKiZKM4IMeta || {},
    component: () => import("/app/pages/oferta.vue")
  },
  {
    name: "orders-id",
    path: "/orders/:id()",
    meta: _91id_93z_45mKuW1rMZYIKaD_45jb7RkOPiwtU5vi28dxEw6FTfUakMeta || {},
    component: () => import("/app/pages/orders/[id].vue")
  },
  {
    name: "orders",
    path: "/orders",
    meta: indexDCqhPzutOZTeEBErhbpXyxz9LSZCrOqFoLSpaIOOmIIMeta || {},
    component: () => import("/app/pages/orders/index.vue")
  },
  {
    name: "policy",
    path: "/policy",
    meta: policyHyFEmCLoA_gbds_45pFYhSvKcu4HdwgUBD6ogvbj_45r9y0Meta || {},
    component: () => import("/app/pages/policy.vue")
  },
  {
    name: "profile",
    path: "/profile",
    meta: profilepJO2Lu3IltPzloUxFV8Oe0uuAaiSUhc1LFMplh8Hci0Meta || {},
    component: () => import("/app/pages/profile.vue")
  },
  {
    name: "rules",
    path: "/rules",
    meta: rulesoW3OsXfUxMGzTjcJPfE4v0oEYZyNpAmFSWz4Vk42uNYMeta || {},
    component: () => import("/app/pages/rules.vue")
  },
  {
    name: "success-order",
    path: "/success-order",
    component: () => import("/app/pages/success-order.vue")
  },
  {
    name: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta?.name,
    path: "/:city/auth/profile",
    component: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIA
  }
]